import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';
import { phrasesFilter } from '../TweetAnalytics/phrases';
import { mentionsFilter } from '../TweetAnalytics/mentions';
import * as tweetHandles from '../constants/twitterHandles';
import { useState } from 'react';
import { getSentimentDetails, LOWEST_TO_HIGHEST, UNKNOWN } from '../TweetAnalytics/sentiment';
import SafeHtml from '../Components/SafeHtml';
const reactStringReplace = require('react-string-replace');

const TweetCard = ({ Tweet, id, Phrases, isRT, Score, searchTerm, Source }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const tweetedUtc = new Date(Tweet.CreatedAtIso);
    const createdAgoInMinutes = Math.round((new Date() - tweetedUtc) / 1000 / 60);
    let timeOrDate;

    if (createdAgoInMinutes < 60) {
        timeOrDate = `${createdAgoInMinutes}m`;
    } else {
        const createdAgoInHours = Math.round(createdAgoInMinutes / 60);
        timeOrDate = createdAgoInHours < 24
            ? `${createdAgoInHours}h`
            : tweetedUtc.toLocaleDateString();
    }

    const phrases = Phrases ? Phrases.filter(phrasesFilter) : [];
    const mentions = Tweet && Tweet.UserMentions
        ? Tweet.UserMentions.map(um => um.UserName).filter(mentionsFilter)
        : [];
    const tweetDetailsLink = `${routes.TWEETS}/${id}`;
    const sentimentDetails = getSentimentDetails(Score ? [{ Score: Score }] : null);
    const highlightedTweetText = searchTerm && searchTerm.length > 0 
        ? reactStringReplace(Tweet.TweetText, searchTerm, (text) => <mark>{text}</mark>)
        : Tweet.TweetText;
    const tweetOrPost = isSourceTruthSocial(Source) ? "Post" : "Tweet";

    return (
        <div className="card shadow mb-4 tweet">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                
                <h6 className="m-0 font-weight-bold text-primary">
                    {Tweet.TweetedBy !== tweetHandles.REAL_DONALD_TRUMP &&
                        <span className="align-middle">&nbsp;{Tweet.UserDetails.FullName}</span>
                    }
                    &nbsp;
                    {Tweet.TweetedBy === tweetHandles.REAL_DONALD_TRUMP
                        ? <Link className="align-middle" to={tweetDetailsLink}>{timeOrDate}</Link>
                        : <span className="align-middle">{timeOrDate}</span>
                    }
                </h6>
                <span className="text-center"><TweetSentimentDisplay sentiment={sentimentDetails} /></span>

                <div className="dropdown no-arrow">
                    <span className="dropdown-toggle" role="button" id="dropdownMenuLink" onClick={() => setMenuOpen(!menuOpen)} aria-haspopup="true" aria-expanded="false" >
                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </span>
                    <div className={`dropdown-menu dropdown-menu-right shadow animated--fade-in${menuOpen ? " show" : ""}`} aria-labelledby="dropdownMenuLink" >
                        {Tweet.TweetedBy === tweetHandles.REAL_DONALD_TRUMP &&
                            <Link to={tweetDetailsLink} className="dropdown-item">{tweetOrPost} Details</Link>
                        }
                        <a target="_blank" rel="noopener noreferrer" className="dropdown-item" href={getTweetUserUrl(Source, Tweet.TweetedBy, id)}>Go to {tweetOrPost}</a>
                        <a target="_blank" rel="noopener noreferrer" className="dropdown-item" href={getTwitterUserUrl(Source, Tweet.TweetedBy)}>Go to User</a>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="tweet-text">
                    {Tweet.OriginalTweet !== null && Tweet.OriginalTweet !== undefined
                        ? <TweetCard Tweet={Tweet.OriginalTweet} id={Tweet.OriginalTweet.TweetId} isRT={true} searchTerm={searchTerm} />
                        : <SafeHtml htmlContent={highlightedTweetText} />
                    }
                </div>
                <div className="more-info">
                    {phrases.length > 0 &&
                        <p><strong>Phrases:</strong> {phrases.join(", ")}</p>
                    }
                    {!isRT && mentions.length > 0 &&
                        <p><strong>UserMentions:</strong> {mentions.map(usermention => `@${usermention}`).join(", ")}</p>
                    }
                </div>
            </div>
        </div>
    );
}

const isSourceTruthSocial = (source) => source && source === "TruthSocial";

const getTwitterUserUrl = (source, user) => source && source === "TruthSocial"
    ? `https://www.truthsocial.com/@${user}`
    : `https://www.twitter.com/${user}`;
const getTweetUserUrl = (source, user, id) => source && source === "TruthSocial"
    ? `https://www.truthsocial.com/@${user}/posts/${id}`
    : `https://www.twitter.com/${user}/status/${id}`;

const TweetSentimentDisplay = ({ sentiment }) => {
    return (
        <React.Fragment>

            {sentiment && sentiment.value !== UNKNOWN.value &&
                <span style={{ verticalAlign: 'middle' }}>
                    {LOWEST_TO_HIGHEST.map((s, i) => {
                        const selected = s.value === sentiment.value;
                        return <i key={i} className={`mr-1 ${s.icon} ${selected ? "text-" + s.color : "text-muted"}`} style={{fontSize: selected ? '150%': '100%', verticalAlign: 'middle'}} />
                    })}
                </span>
            }
        </React.Fragment >

    )
}

export default TweetCard