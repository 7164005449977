import React from 'react';

const StandardCard = ({heading, body}) =>
    <div className="card shadow mb-4 tweet">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold text-primary">
                {heading}
            </h6>
        </div>
        <div className="card-body">
            {body}
        </div>
    </div>

export default StandardCard;