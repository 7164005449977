import React from 'react';
import { Helmet } from 'react-helmet';

const NotFoundPage = () =>
    <div>
        <Helmet>
            <title>President Tweety | Not Found</title>
        </Helmet>
        Not Found!
    </div>

export default NotFoundPage;