import React, { useState, useEffect } from 'react';
import SimpleCard from './Components/SimpleCard';
import TweetCard from './Tweets/TweetCard';
import { getPhraseDetails } from './TweetAnalytics/phrases';
import StandardCard from './Components/StandardCard';
import { getMentionDetails } from './TweetAnalytics/mentions';
import { Link } from 'react-router-dom';
import * as routes from './constants/routes';
import TweetsOverTimeGraph from './Graphs/TweetsOverTime';
import { getSelfRetweetsCount } from './TweetAnalytics/retweets';
import { getSentimentDetails } from './TweetAnalytics/sentiment';
import { Helmet } from 'react-helmet';
import Loading from './Components/Loading';

const DashboardPage = () => {
    const [tweets, setTweets] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    useEffect(() => {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        const url = `/api/tweets?after=${date.toISOString()}`;
        setIsloading(true);
        fetch(url, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTweets(data);
            })
            .finally(() => setIsloading(false));
    }, []);

    const phraseDetails = getPhraseDetails(tweets);
    const mentionDetails = getMentionDetails(tweets);
    const tweetColor =  tweets.length <= 6 
    ? "success" 
    : tweets.length <= 12 
        ? "primary"
        : tweets.length <= 18
            ? "secondary"
            : tweets.length <= 24
                ? "warning"
                : "danger";
    return (
        <React.Fragment>
            <Helmet>
                <title>President Tweety | Dashboard</title>
            </Helmet>
            <h1 className="h3 mb-2 text-gray-800">Dashboard (Last 24 Hours)</h1>
            {isLoading && 
                <Loading />
            }
            {tweets.length > 0 &&
                <div className="animated--grow-in">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 mb-4">
                            <SimpleCard text="Tweets" value={tweets.length} colorClass={tweetColor} icon="fab fa-twitter" />
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4">
                            <MostPopularPhraseCard phraseDetails={phraseDetails} />
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4">
                            <SelfRetweetCard tweetObjects={tweets} />
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4">
                            <SentimentCard tweetObjects={tweets} />
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-6 col-sm-12 col-12 order-md-6">
                            <TweetsOverTimeGraph tweetObjects={tweets} />
                            <StandardCard heading="Phrases" body={phraseDetails.sort((a, b) => b.count - a.count).map(phraseDetail => `${phraseDetail.phrase} (${phraseDetail.count})`).join(", ")} />
                            <StandardCard heading="Mentions" body={mentionDetails.sort((a, b) => b.count - a.count).map(mentionDetail => `@${mentionDetail.mention} (${mentionDetail.count})`).join(", ")} />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 order-md-1">
                            {tweets.map((tweet) => <TweetCard key={tweet.id} {...tweet} />)}
                            <Link to={routes.TWEETS} className="btn btn-primary mb-3 d-block">Get All</Link>
                        </div>
                    </div>
                </div>
            }

        </React.Fragment>
    );
};

export default DashboardPage;

const MostPopularPhraseCard = ({ phraseDetails }) => {
    const mostPopular = phraseDetails.length > 0
        ? phraseDetails.sort((a, b) => b.count - a.count)[0]
        : { phrase: "", count: 0 };
    const color =  mostPopular.count === 0
        ? "success" 
        : mostPopular.count === 1
            ? "primary"
            : mostPopular.count === 2
                ? "secondary"
                : mostPopular.count === 3
                    ? "warning"
                    : "danger";
    return <SimpleCard text="Most Popular Phrase" value={mostPopular.count > 0 ? `${mostPopular.phrase} (${mostPopular.count})` : ""} colorClass={color} icon="fas fa-fire" />
}

const SelfRetweetCard = ({ tweetObjects }) => {
    const selfRetweetCount = getSelfRetweetsCount(tweetObjects);
    return <SimpleCard 
        text="Self Retweet Count" 
        value={selfRetweetCount} 
        colorClass={selfRetweetCount === 0 ? "secondary" : selfRetweetCount < 2 ? "warning" : "danger" }
        icon="fas fa-retweet" 
    />
}

const SentimentCard = ({ tweetObjects }) => {
    const sentimentDetails = getSentimentDetails(tweetObjects);
    return (sentimentDetails && <SimpleCard 
        text="Sentiment" 
        value={`${sentimentDetails.value} (${Math.round(sentimentDetails.average * 100)}/100)`} 
        colorClass={sentimentDetails.color}
        icon={sentimentDetails.icon} 
    />) || null;
}


