import React, { useMemo }from 'react';
import { Chart } from 'react-charts';

const TweetsOverTimeGraph = ({ tweetObjects }) => {

    const data = useMemo(
        () => {
            const tweetData = getData(tweetObjects).map(hours => ({ x: hours.hour, y: hours.count}));
            return [
          {
            label: 'Tweets Per Hour',
            data: tweetData,
          }
        ]},
        [tweetObjects]
      )
     
      const axes = useMemo(
        () => [
          { primary: true, type: 'time', position: 'bottom' },
          { type: 'linear', position: 'left' }
        ],
        []
      )

    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Tweets Per Hour</h6>
            </div>
            <div className="card-body" >
              <div
                style={{
                  height: "300px",
                  margin: "10px"
                }}
                >
                <Chart data={data} axes={axes} style={{maxHeight: "400px"}} />
              </div>
            </div>
        </div>
    );
};

const getData = (tweetObjects) => {
    let hours = [];
    for(let i = 0; i < 23; ++i) {
        const beginningOfHourDate = new Date();
        beginningOfHourDate.setHours(new Date().getHours() - i);
        beginningOfHourDate.setMinutes(0);
        hours.push({
            hour: beginningOfHourDate,
            count: tweetObjects.filter(to => {
                const createdAtDate = new Date(to.Tweet.CreatedAtIso);
                return createdAtDate.getDay() === beginningOfHourDate.getDay() && createdAtDate.getHours() === beginningOfHourDate.getHours();
            }).length
        });
    }
    return hours.reverse();
}

export default TweetsOverTimeGraph;