import React, { useEffect, useState } from 'react';
import Tweet from './TweetCard';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Loading from '../Components/Loading';

const TweetsDetailPage = () => {
    const { id } = useParams();
    const [tweet, setTweet] = useState(null);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        const url = `/api/tweets/${id}`;
        setIsloading(true);
        fetch(url, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTweet(data);
            })
            .finally(() => setIsloading(false)); 
    }, [id]);

    return (
        <div>
            {isLoading && <Loading />}
            {tweet &&
                <React.Fragment>
                    <Helmet>
                        <title>President Tweety | Tweet {tweet.id}</title>
                    </Helmet>
                    <Tweet key={tweet.id} {...tweet} />
                </React.Fragment>
            }
        </div>
    )
}

export default TweetsDetailPage