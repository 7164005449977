import React from 'react';
import { Helmet } from 'react-helmet';

const AboutPage = () =>
    <div>
        <Helmet title="President Tweety | About" />
        <h1 className="h3 mb-2 text-gray-800">About</h1>
        <div className="row">
            <div className="col-12">
                <p>President Tweety pulls in every tweet by Donald Trump (<a href="https://twitter.com/realDonaldTrump" target="_blank" rel="noopener noreferrer">@realDonalTrump</a>), and attempts to analyze it for sentiment and key phrases using <a href="https://azure.microsoft.com/en-us/services/cognitive-services/text-analytics/" target="_blank"  rel="noopener noreferrer">Azure Cognitive Services</a>. Tweets are currently only from July 2020, and on while new features are being added daily.</p>
            </div>
        </div>
    </div>

export default AboutPage;