import React from 'react';
import { useState } from 'react';
import * as routes from './constants/routes';


const TopNav = ({toggleMenu}) => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <React.Fragment>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow" style={{ zIndex: 100000 }}>

                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={() => toggleMenu()} >
                    <i className="fa fa-bars"></i>
                </button>

                <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search" method="get" action={routes.TWEETS}>
                    <div className="input-group">
                        <input
                            type="text"
                            name="searchTerm"
                            className="form-control bg-light border-0 small"
                            placeholder="Search for..."
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </nav>
        </React.Fragment>
    )
}

export default TopNav;