import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from './constants/routes';

const NavMenu = ({hide}) =>
    <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${hide ? "collapse" : ""}`} id="accordionSidebar" style={{zIndex: 1000000}}>
        <Link to="/" className="sidebar-brand d-flex align-items-center justify-content-center">
            <div className="sidebar-brand-icon">
                {/* <i className="fas fa-laugh-wink"></i> */}
                <i className="fas fa-user-circle"></i>
            </div>
            <div className="sidebar-brand-text mx-3">President Tweety</div>
        </Link>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item">
            <Link to={routes.DASHBOARD} className="nav-link">
                Dashboard
            </Link>
        </li>

        <li className="nav-item">
            <Link to={routes.TWEETS} className="nav-link">All Tweets</Link>
        </li>

        <li className="nav-item">
            <Link to={routes.ABOUT} className="nav-link">About</Link>
        </li>
    </ul>

export default NavMenu;