import React from 'react';

const Loading = () =>
    <React.Fragment>
        <div className="fixed-top" style={{ background: '#000', opacity: '.1', top: 0, left: 0, right: 0, bottom: 0 }}>
        </div>
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>

    </React.Fragment>

export default Loading;