import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import TweetsList from './TweetsList';
import TweetsDetailPage from './TweetDetailPage';
import { Helmet } from 'react-helmet';
import useQuery from '../hooks/useQuery';

const TweetsPage = () => {
    let match = useRouteMatch();
    let query = useQuery();

    return (
        <React.Fragment>
            <Helmet>
                <title>President Tweety | Tweets</title>
            </Helmet>
            <Switch>
                <Route path={`${match.path}/:id`}>
                    <TweetsDetailPage />
                </Route>
                <Route>
                    <TweetsList searchTerm={(query.get("searchTerm") ?? "").trim()} />
                </Route>
            </Switch>
        </React.Fragment>
    );
}

export default TweetsPage;