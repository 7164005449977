import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import DashboardPage from './DashboardPage';
import TweetsPage from './Tweets/TweetsPage';
import NotFoundPage from './NotFoundPage';
import NavMenu from './NavMenu';
import TopNav from './TopNav';
import * as routes from './constants/routes';
import { Helmet } from 'react-helmet';
import AboutPage from './AboutPage';
import withTracking from './Components/withTracking';

function App() {
  const [hideNavMenu, setHideMenu] = useState(false);

  return <Router>
    <Helmet>
      <title>President Tweety</title>
      <meta name="description" content="Sentiment/Analytics on Trump's favorite medium - Twitter" />
    </Helmet>
    <div id="wrapper">
      <NavMenu hide={hideNavMenu} />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopNav toggleMenu={() => setHideMenu(!hideNavMenu)} />
          <div className="container-fluid">
            <Switch>
              <Route exact path={routes.DASHBOARD} component={withTracking(() => <DashboardPage />)} />
              <Route path={routes.TWEETS} component={withTracking(() => <TweetsPage />)} />
              <Route path={routes.ABOUT} component={withTracking(() => <AboutPage />)} />
              <Route component= {withTracking(() => <NotFoundPage />)} />
            </Switch>
          </div>

        </div>
      </div>
    </div>
  </Router>
}

export default App;
